
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import momentPlugin from '@fullcalendar/moment';

const moment = require('moment');

		moment.locale('ja', {
			// weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
			weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
		});

var calendarEl = document.getElementById('calendar');
let calendar = new Calendar(calendarEl, {
		plugins: [dayGridPlugin, timeGridPlugin, googleCalendarPlugin, momentPlugin],
		header: {
			left: 'title',
			center: 'today prev,next',
			right: 'timeGridWeek dayGridMonth'
		},


		views: {
			timeGrid: {
				columnHeaderHtml: function(date) {
					let m = moment(date);
					return m.format('MM.DD<br>(ddd)');
				},
			},
			dayGrid: {
				columnHeaderHtml: function(date) {
					let m = moment(date);
					return m.format('ddd');
				},

			}
		},

		slotLabelFormat: 'H:mm',

		googleCalendarApiKey: 'AIzaSyCNfU-7Mo83_E6usdaTNXW3wz3bhY0TEAE',

		eventSources: [
			{
				googleCalendarId: 'pc3d6dhq25cgddnra91qad4q04@group.calendar.google.com',
				className: 'calendar-event',
				groupId: 'event'
			},
			{
				googleCalendarId: 'area898450@gmail.com',
				className: 'calendar-open',
				groupId: 'open'
			},
			{
				googleCalendarId: '5vns8i8eu4h63d0k4gangr1bm0@group.calendar.google.com',
				className: 'calendar-reserved',
				groupId: 'reserved'
			}
		],

		buttonText: {
			today: '今日',
			week: '週',
			month: '月'
		},
		firstDay: 1,//最初は月曜
		locale: 'ja',//日本語化
		defaultView: 'dayGridMonth',

		eventTimeFormat: {//時間を2桁
			hour: 'numeric',
			minute: '2-digit'
		},

		allDaySlot: false,//終日スロット
		minTime: '08:00',
		maxTime: '23:00',
		nowIndicator: true,//現在の時刻を示すマーカー

		selectable: true,
		selectHelper: true,

		eventClick: function(info) {
			info.jsEvent.preventDefault();

			if(info.event.url) {
				var eventMonth = info.event.start.getMonth()+1;
				var eventDate = info.event.start.getDate();
				var eventStart = info.event.start.getHours()+':'+('0'+info.event.start.getMinutes()).slice(-2);
				var eventEnd = info.event.end.getHours()+':'+('0'+info.event.end.getMinutes()).slice(-2);
				var eventTitle = info.event.title;
				// var eventID = info.event.groupId;
				var eventContent = '';
				var eventDescription = info.event.extendedProps.description;

				if(typeof eventDescription === 'undefined'){}
				else{ eventContent = eventDescription;}

				var eventClose = '<div class="bubble-close">×</div>';
				$('.bubble').fadeOut(1000).remove();
				$('#calendar').append('<div class="bubble"></div>');

				$('.bubble').html(
					'<div class="bubble-date">'+eventMonth+'/'+eventDate+' '+eventStart+'-'+eventEnd+'</div>'+
					'<div class="bubble-title">'+eventTitle+'</div>'+
					'<div class="bubble-content">'+eventContent+'</div>'+eventClose
				).hide().fadeIn(500);

				var positionX = info.jsEvent.pageX;
				var positionY = info.jsEvent.pageY+20;
				var bubbleWidth = $('.bubble').outerWidth();
				var windowWidth = $(window).width();

				if(windowWidth - positionX < bubbleWidth){
					positionX = positionX-bubbleWidth;
					$('.bubble').addClass('arrow-right').removeClass('arrow-left arrow-center');
					$('.bubble').css({
						top:(positionY),left:(positionX),display:'block'
					});
				}else if(positionX < bubbleWidth/2){
					$('.bubble').addClass('arrow-left').removeClass('arrow-right arrow-center');
					$('.bubble').css({
						top:(positionY),left:(positionX),display:'block'
					});
				}else{
					positionX = positionX-bubbleWidth/2;
					$('.bubble').addClass('arrow-center').removeClass('arrow-left arrow-right');
					$('.bubble').css({
						top:(positionY),left:(positionX),display:'block'
					});
				}

				$('.bubble a[href^=http]').not('[href*="'+location.hostname+'"]').attr('target', '_blank');

				$(window).on('click.bubble', function(e) {
					e.stopPropagation();
					if(
						!$(e.target).hasClass('fc-content') &&
						!$(e.target).hasClass('fc-time-grid-event') &&
						!$(e.target).closest('.fc-content').length
						){ $('.bubble .bubble-close').trigger('click');}
				});

				$('.bubble .bubble-close').on('click', function(){
					$('.bubble').fadeOut(300).remove();
					$(window).off('click.bubble');
				});

			}

		},
});
		$('#calendar').append('<div class="calendar-usage"><div class="calendar-event">イベント開催</div><div class="calendar-open">一般オープン</div><div class="calendar-reserved">貸し切り</div></div>');
		calendar.render();
